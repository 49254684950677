import React, { useContext, useMemo } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import clsx from "clsx";

import { MenuButton } from "~components/utils/menu-button";
import { Navigation } from "~components/utils/navigation";
import { Language, LanguageContext } from "~context/LanguageContext";
import { useHeader, HeaderTheme } from "~hooks/useHeader";
import * as styles from "./index.module.scss";

const Component: React.FC<{
  theme: HeaderTheme;
  isMenuOpen: boolean;
  isMobile: boolean;
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  gatsbyData: GatsbyHeaderComponentQuery;
  headerRef: React.RefObject<HTMLElement>;
  observePointRef: React.RefObject<HTMLDivElement>;
  language: Language;
  className?: string;
}> = ({
  theme,
  isMenuOpen,
  isMobile = true,
  handleClick,
  gatsbyData,
  headerRef,
  observePointRef,
  language,
}) => {
  const logoSrc = useMemo(() => {
    return theme === "Transparent"
      ? gatsbyData.flatt_security_logo_white?.publicURL
      : gatsbyData.flatt_security_logo?.publicURL;
  }, [gatsbyData, theme]);

  if (isMobile === false) {
    return (
      <div className={styles.Header_Wrapper}>
        <header
          className={clsx(styles.Header, styles[`Header__${theme}`])}
          ref={headerRef}
        >
          <div
            className={clsx(
              styles.Header_Left,
              styles[`Header_Left__${theme}`],
            )}
          >
            <Link to="/">
              <img
                className={clsx(
                  styles.Header_Logo,
                  styles[`Header_Logo__${theme}`],
                )}
                src={logoSrc || ""}
                alt=""
              />
            </Link>
          </div>
          <div className={styles.Header_RightWrapper}>
            <div
              className={clsx(
                styles.Header_Right,
                styles[`Header_Right__${theme}`],
              )}
            >
              <Navigation theme={theme} language={language} />
            </div>
          </div>
        </header>
        <div ref={observePointRef} className={styles.Header_ObserverPoint} />
      </div>
    );
  }

  return (
    <div className={styles.Header_Wrapper}>
      <header className={styles.Header}>
        <div className={styles.Header_Left}>
          <Link to="/">
            <img className={styles.Header_Logo} src={logoSrc || ""} alt="" />
          </Link>
        </div>
        <div className={styles.Header_Right}>
          <MenuButton handleClick={handleClick} isOpen={isMenuOpen} />
        </div>
      </header>
      {isMenuOpen && (
        <div className={styles.MenuPanel}>
          <Navigation theme={theme} language={language} />
        </div>
      )}
    </div>
  );
};

export type Props = {
  useTransition?: boolean;
  defaultTheme?: HeaderTheme;
};

export const Header: React.FC<Props> = (props) => {
  const { defaultTheme, useTransition } = props;
  const data = useStaticQuery(graphql`
    query HeaderComponent {
      flatt_security_logo: file(
        relativePath: { eq: "flatt_security_logo.svg" }
      ) {
        publicURL
      }
      flatt_security_logo_white: file(
        relativePath: { eq: "flatt_security_logo_white.svg" }
      ) {
        publicURL
      }
    }
  `);

  const {
    theme,
    isMobile,
    isOpen,
    handleClickMenuButton,
    headerRef,
    observePointRef,
  } = useHeader(defaultTheme, useTransition);
  const { language } = useContext(LanguageContext);

  return (
    <Component
      {...props}
      gatsbyData={data}
      theme={theme}
      isMobile={isMobile}
      isMenuOpen={isOpen}
      handleClick={handleClickMenuButton}
      headerRef={headerRef}
      observePointRef={observePointRef}
      language={language}
    />
  );
};
