import useWindowResize from "./useWindowResize";

const defaultThreshold = 959;

const isClient = typeof window !== "undefined";

const getWidth = () => {
  const getWindowSize = () => {
    if (!isClient) {
      return 0;
    }
    return window.innerWidth;
  };
  const width = getWindowSize();
  return width;
};

const useIsMobile: (threshold?: number) => boolean = (threshold) => {
  const width = useWindowResize(getWidth).size;

  return width <= (threshold || defaultThreshold);
};

export default useIsMobile;
