import { useState, useEffect } from "react";

const isClient = typeof window !== "undefined";

const getWidth = () => {
  const getWindowSize = () => {
    if (!isClient) {
      return 0;
    }
    return window.innerWidth;
  };
  const width = getWindowSize();
  return width;
};

const useWindowResize: (callback?: () => number) => { size: number } = (
  callback = getWidth,
) => {
  const [size, setSize] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      setSize(callback);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    const cleanUp = () => {
      window.removeEventListener("resize", handleResize);
    };
    return cleanUp;
  });
  return { size };
};

export default useWindowResize;
