import React from "react";

import clsx from "clsx";
import * as styles from "./index.module.scss";

// NOTE (y0n3uchy): This circular dependency is weird a bit,
// but currently it's ok cuz `Hamburger` and `MenuButton` totally depend on each other.
import { MenuButtonTheme } from "~components/utils/menu-button";

type Props = {
  isOpen: boolean;
  theme?: MenuButtonTheme;
};

export const Hamburger: React.FC<Props> = (props) => {
  const { isOpen, theme } = props;

  return (
    <div
      className={clsx(
        theme === undefined ? styles.Hamburger : styles[`Hamburger__${theme}`],
        isOpen ? styles.Hamburger__Open : styles.Hamburger__Close,
      )}
    >
      <span className={clsx(styles.Hamburger_Bar, styles.Hamburger_Bar__Top)} />
      <span
        className={clsx(styles.Hamburger_Bar, styles.Hamburger_Bar__Center)}
      />
      <span
        className={clsx(styles.Hamburger_Bar, styles.Hamburger_Bar__Bottom)}
      />
    </div>
  );
};
