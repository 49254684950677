import React from "react";
import clsx from "clsx";
import { Link } from "gatsby";
import { HeaderTheme } from "~hooks/useHeader";
import * as styles from "./index.module.scss";

type Props = {
  link?: string;
  theme: HeaderTheme;
  children: React.ReactNode;
};

export const HeaderNavigationItem: React.FC<Props & { className?: string }> = ({
  link,
  children,
}) => {
  if (link == null) {
    return <div className={clsx(styles.HeaderNavigationItem)}>{children}</div>;
  }
  return (
    <Link className={styles.HeaderNavigationItem} to={link}>
      {children}
    </Link>
  );
};
