import React, { FC } from "react";
import { Hamburger } from "~components/utils/hamburger";
import * as styles from "./index.module.scss";

export type MenuButtonTheme = "Default" | "Kenro";

type Props = {
  theme?: MenuButtonTheme;
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isOpen: boolean;
};

export const MenuButton: FC<Props> = ({ theme, handleClick, isOpen }) => {
  return (
    <button type="button" onClick={handleClick} className={styles.MenuButton}>
      <Hamburger isOpen={isOpen} theme={theme} />
    </button>
  );
};
