import React, { useMemo, FC } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import clsx from "clsx";
import { Language } from "~context/LanguageContext";
import * as styles from "./index.module.scss";

export type FooterContent = {
  name?: string;
  meta: {
    name: string;
    link?: string;
    childMeta?: {
      name: string;
      link?: string;
    }[];
  }[];
};

const footerContents: FooterContent[] = [
  {
    name: "会社情報",
    meta: [
      {
        name: "会社概要",
        link: "/company",
      },
      {
        name: "採用情報",
        link: "https://recruit.flatt.tech",
      },
      {
        name: "ニュース",
        link: "/news",
      },
      {
        name: "技術ブログ",
        link: "https://blog.flatt.tech/",
      },
      {
        name: "#FlattSecurityMagazine",
        link: "/magazine",
      },
      {
        name: "脆弱性リサーチプロジェクト",
        link: "/cve",
      },
    ],
  },
  {
    name: "サービス紹介",
    meta: [
      {
        name: "セキュリティ診断",
        link: "/assessment",
        childMeta: [
          {
            name: "AWS・Google Cloud・Azure診断",
            link: "/assessment/cloud_platform",
          },
          {
            name: "Firebase診断",
            link: "/assessment/firebase",
          },
          {
            name: "Webアプリケーション診断",
            link: "/assessment/web_app",
          },
          {
            name: "スマートフォンアプリ診断",
            link: "/assessment/sp_app",
          },
          {
            name: "スマートフォンゲーム診断",
            link: "/assessment/sp_game",
          },
          {
            name: "プラットフォーム診断",
            link: "/assessment/platform",
          },
          {
            name: "IoT診断",
            link: "/assessment/iot",
          },
          {
            name: "ペネトレーションテスト",
            link: "/assessment/penetration_test",
          },
          {
            name: "SPA診断",
            link: "/assessment/spa",
          },
          {
            name: "GraphQL診断",
            link: "/assessment/graphql",
          },
          {
            name: "ブロックチェーン診断",
            link: "/assessment/blockchain",
          },
          {
            name: "ホワイトボックス診断",
            link: "/assessment/plan/whitebox",
          },
          {
            name: "リスクフォーカス型プラン",
            link: "/assessment/plan/risk_focus",
          },
        ],
      },
      {
        name: "Shisho Cloud(シショウ クラウド)",
        link: "https://shisho.dev/ja",
      },
      {
        name: "KENRO(ケンロー)",
        link: "/kenro",
      },
    ],
  },
  {
    name: "お問い合わせ",
    meta: [
      {
        name: "お問い合わせフォーム",
        link: "/contact",
      },
      {
        name: "採用お問い合わせフォーム",
        link: "https://forms.gle/i89Vxjpc6JCgAGod6",
      },
      {
        name: "プライバシーポリシー",
        link: "/privacy-policy",
      },
    ],
  },
];

const footerContentsEnglish: FooterContent[] = [
  {
    meta: [
      {
        name: "Research",
        link: "/research",
      },
      {
        name: "Recruitment",
        link: "https://recruit.flatt.tech",
      },
      {
        name: "Contact",
        link: "/en/#contact",
      },
      {
        name: "日本語",
        link: "/",
      },
    ],
  },
];

const FooterContentComponent: FC<FooterContent> = ({ name, meta }) => {
  return (
    <div className={styles.Content}>
      {name && <h4>{name}</h4>}
      {meta.map((value) => {
        if (!value.link) {
          return <p key={value.name}>{value.name}</p>;
        }

        if (value.childMeta) {
          return (
            <div key={value.name}>
              <Link className={styles.Content_Link} to={value.link}>
                {value.name}
              </Link>

              <ul className={styles.Content_ListWrapper}>
                {value.childMeta.map((nestedValue) => (
                  <li key={nestedValue.name} className={styles.Content_List}>
                    <Link className={styles.Content_Link} to={nestedValue.link}>
                      {nestedValue.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          );
        }

        return (
          <Link
            className={styles.Content_Link}
            key={value.name}
            to={value.link}
          >
            {value.name}
          </Link>
        );
      })}
    </div>
  );
};

const Component: React.FC<{
  contents: FooterContent[];
}> = ({ contents }) => {
  const data = useStaticQuery(graphql`
    query {
      flatt_security_logo_white: file(
        relativePath: { eq: "flatt_security_logo_white.svg" }
      ) {
        extension
        publicURL
      }
    }
  `);

  return (
    <div className={styles.Footer}>
      <div className={styles.Footer_List}>
        {contents.map((content, i) => {
          return (
            <FooterContentComponent
              key={i}
              name={content.name}
              meta={content.meta}
            />
          );
        })}
      </div>
      <Link to="/">
        <img
          className={styles.Footer_Logo}
          src={data.flatt_security_logo_white.publicURL}
          alt=""
        />
      </Link>
    </div>
  );
};

type Props = {
  lang?: Language;
};

export const Footer: FC<Props> = ({ lang = "ja" }) => {
  const contents = useMemo(
    () => (lang === "en" ? footerContentsEnglish : footerContents),
    [lang],
  );
  return <Component contents={contents} />;
};
