// extracted by mini-css-extract-plugin
export var Header = "index-module--Header--qZjwT";
export var Header_Left = "index-module--Header_Left--0582t";
export var Header_Logo = "index-module--Header_Logo--Rkvn6";
export var Header_Logo__TwoPane = "index-module--Header_Logo__TwoPane--N3i-A";
export var Header_ObserverPoint = "index-module--Header_ObserverPoint--AwZXn";
export var Header_RightWrapper = "index-module--Header_RightWrapper--dYJNe";
export var Header_Right__TwoPane = "index-module--Header_Right__TwoPane--XTmGF";
export var Header_Wrapper = "index-module--Header_Wrapper--uD+Vr";
export var Header__Transparent = "index-module--Header__Transparent--PgYp-";
export var Header__TwoPane = "index-module--Header__TwoPane--sGWhQ";
export var MenuPanel = "index-module--MenuPanel--dAeV7";
export var activeMenuPanel = "index-module--active-menu-panel--dsBuc";
export var transparent = "index-module--transparent--MUjDu";