import { useEffect } from "react";
import { isClient } from "~utils/isClient";
import "intersection-observer";

export const useIntersectionObserver = (
  observePointRef: React.RefObject<HTMLElement>,
  callback: IntersectionObserverCallback,
  options: IntersectionObserverInit = {},
) => {
  useEffect(() => {
    if (!isClient) return undefined;
    const observer = new IntersectionObserver(callback, options);
    if (observePointRef.current != null)
      observer.observe(observePointRef.current);

    return () => {
      observer.disconnect();
    };
  }, [observePointRef, callback, options]);
};
