import { useState, useCallback, useRef, useEffect, useContext } from "react";
import useIsMobile from "./useIsMobile";
import { useIntersectionObserver } from "./useIntersectionObserver";
import { ModalContext } from "~context/ModalContext";

export type HeaderTheme = "Default" | "Transparent" | "TwoPane";

export type HeaderState = {
  theme: HeaderTheme;
  isMobile: boolean;
  isOpen: boolean;
  headerRef: React.RefObject<HTMLElement>;
  observePointRef: React.RefObject<HTMLDivElement>;
  handleClickMenuButton(e: React.MouseEvent<HTMLButtonElement>): void;
};

export const useHeader: (
  defaultTheme?: HeaderTheme,
  useTransition?: boolean,
) => HeaderState = (defaultTheme = "Default", useTransition = false) => {
  const [theme, setTheme] = useState<HeaderState["theme"]>(defaultTheme);
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState<HeaderState["isOpen"]>(false);
  const headerRef = useRef<HTMLElement>(null);
  const observePointRef = useRef<HTMLDivElement>(null);
  const { dispatch } = useContext(ModalContext);
  useIntersectionObserver(
    observePointRef,
    (entries) => {
      if (isMobile === true) {
        return;
      }
      if (useTransition === false) {
        return;
      }
      entries.forEach((entry) => {
        if (headerRef.current == null) {
          return;
        }
        if (entry.isIntersecting) {
          setTheme("Transparent");
        } else {
          setTheme(defaultTheme);
        }
      });
    },
    {
      rootMargin: "200px 0px 0px 0px",
    },
  );

  useEffect(() => {
    if (isMobile === true) setTheme(defaultTheme);
  }, [isMobile, defaultTheme]);

  const handleClickMenuButton = useCallback(() => {
    setIsOpen((prev) => !prev);
    dispatch({ type: "toggle" });
  }, [dispatch]);

  return {
    theme,
    isMobile,
    isOpen,
    headerRef,
    observePointRef,
    handleClickMenuButton,
  };
};
